<template>
  <a-modal v-model:open="modalView" title="Configurare order" @cancel="close" @ok="close">
    <a-button @click="addOrder" style="margin-top: 10px; margin-bottom: 20px"><PlusOutlined /> Add</a-button>
    <template v-for="(order, index) in localTemplate.object.view.default.order" :key="index">
      <a-row style="margin-bottom: 10px">
        <a-col class="col-5">
          Field Id:<br />
          <a-select v-model:value="order.fieldId" :options="selectedBlueprintFieldsOptions" style="width: 100%"></a-select>
        </a-col>

        <a-col class="col-5">
          Order:<br />
          <a-select v-model:value="order.order" style="width: 100%">
            <a-select-option value="asc"> Ascend </a-select-option>
            <a-select-option value="desc"> Descend </a-select-option>
          </a-select>
        </a-col>
        <a-col class="col-2">
          <a-tooltip title="Remove condition"></a-tooltip>
          <a-button @click="removeOrder(index)" style="margin-top: 22px">
            <MinusOutlined />
          </a-button>
        </a-col>
      </a-row>
    </template>

    <template #footer>
      <a-button key="submit" type="primary" @click="close" style="margin-top: 20px"> Ok </a-button>
    </template>
  </a-modal>
</template>

<script>
import { TemplatesActions } from '@/apps/templateManagement/shared/templateManagement.store';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons-vue';

export default {
  name: 'ElementOrder',
  components: { MinusOutlined, PlusOutlined },
  props: ['selectedBlueprint', 'template', 'modalConfigOrderVisible'],
  emits: ['update:modalConfigOrderVisible'],
  data() {
    return {
      modalView: this.modalConfigOrderVisible,
      localTemplate: this.template ?? null,
    };
  },
  mounted() {
    this.localTemplate = this.template ?? null;
    this.localTemplate.object.view.default.order = Array.isArray(this.localTemplate.object.view.default.order)
      ? this.localTemplate.object.view.default.order
      : [this.localTemplate.object.view.default.order];
  },
  watch: {
    localTemplate: {
      // update stored template.
      deep: true,
      handler(value) {
        TemplatesActions.setTemplate(value);
      },
    },
  },
  methods: {
    close() {
      this.$emit('update:modalConfigOrderVisible', false);
    },
    addOrder() {
      this.localTemplate.object.view.default.order.push({ fieldId: null, order: 'desc' });
    },
    removeOrder(index) {
      this.localTemplate.object.view.default.order.splice(index, 1);
    },
  },
  computed: {
    selectedBlueprintFieldsOptions() {
      const options = [];

      if (this.selectedBlueprint && Array.isArray(this.selectedBlueprint.fields)) {
        this.selectedBlueprint.fields.forEach(field => {
          options.push({
            value: field._id,
            label: field.label,
          });
        });
      }

      options.push({
        value: '_created_at',
        label: 'Created at',
      });

      return options;
    },
  },
};
</script>
