<template>
  <a-card size="small" class="canBeDragged" :title="element[this.object.view?.default?.kanban?.cardsTitleFiledId] ?? ''">
    <template #extra>
      <div v-if="configFieldsElements.find(field => field.id === 'action')">
        <a-button v-if="element?._ownerPermissions?.canRead" @click="openInstance('view', element)" size="small" class="mr-2" type="primary"
          ><EyeOutlined />
        </a-button>
        <a-button
          v-if="element?._ownerPermissions?.canEdit && object.view.default.showEditButton"
          @click="openInstance('details', element)"
          size="small"
          class="mr-2"
          type="primary"
          ><EditOutlined />
        </a-button>
        <a-button
          :loading="deleteLoading === element._id"
          @click="onDelete(element)"
          v-if="element?._ownerPermissions?.canDelete && object.view.default.showDeleteButton"
          size="small"
          type="primary"
          danger
          ><DeleteOutlined />
        </a-button>
      </div>
    </template>
    <template v-for="(field, index) in configFieldsElements" :key="index">
      <p v-if="field.id !== 'action'">
        <strong>{{ field.label || '' }}:</strong>
        <span style="margin-left: 5px">
          <template v-if="Array.isArray(element[field.id])">
            {{ element[field.id].join(', ') }}
          </template>
          <template v-else>{{ element[field.id] }}</template>
        </span>
      </p>
    </template>
  </a-card>
</template>
<script>
import { NewTemplatesGetters, NewTemplatesMutations, NewTemplatesActions } from '@/apps/templateDataSystem/shared/newTemplateDataSystem.store';
import { DeleteOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons-vue';
import { message } from 'ant-design-vue';

export default {
  components: {
    DeleteOutlined,
    EditOutlined,
    EyeOutlined,
  },
  props: ['object', 'element'],
  data() {
    return {
      deleteLoading: null,
      kanbanColumnsField: null,
      kanbanColumnsInstances: [],
      instance: null,
      cardsOrderFiledId: this.object.view?.default?.kanban?.cardsOrderFiledId,
      kanbanData: [],
    };
  },
  computed: {
    configFieldsElements() {
      return this.object.view.listFields.filter(field => field.show);
    },
    blueprint() {
      return NewTemplatesGetters.getBlueprint(this.object.blueprintId);
    },
    filterConditions() {
      return NewTemplatesGetters.getFilterConditions(this.object._id);
    },
  },
  methods: {
    openInstance(name, instance) {
      this.$emit('openInstance', name, instance);
    },

    async onDelete(instanceData) {
      this.$confirm({
        title: 'Confirm',
        content: 'Sure you want to delete?',
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'Cancel',
        onOk: async () => {
          this.deleteLoading = instanceData._id;
          const response = await NewTemplatesActions.deleteInstance(this.object._id, this.blueprint._id, instanceData._id);
          if (!response?.error) {
            NewTemplatesMutations.SET_OPEN_CLOSE_MODAL(`details-${this.object._id}`, false);
          } else {
            message.warning(response.message);
          }
          this.deleteLoading = null;
          this.$emit('fetchInstances', null, null, null, this.object.blueprintId);
        },
      });
    },
  },
};
</script>

<style scoped>
.canBeDragged {
  transition: none !important;

  &.destination-item {
    cursor: move;
    opacity: 0;
  }
  &.dragging-item {
    opacity: 1 !important;
  }
}
</style>
