<template>
  <a-modal v-model:open="modalView" title="Configurare setari element" :width="1000" @cancel="close">
    <a-tabs default-active-key="1">
      <a-tab-pane key="1" tab="Element settings">
        <div>
          <a-row>
            <a-col :span="6" style="padding-right: 20px">
              <a-checkbox v-model:checked="localTemplate.object.view.default.showTitle"> Show title </a-checkbox>
              <div></div>
              Custom title<br />
              <a-input v-model:value="localTemplate.object.view.default.customTitle" :placeholder="selectedBlueprint.name" />
            </a-col>
            <a-col :span="6">
              <a-checkbox v-model:checked="localTemplate.object.view.default.showSearchTable"> showSearchTable </a-checkbox>
            </a-col>
          </a-row>
        </div>
        <a-divider orientation="center"> Cards settings </a-divider>
        <div style="margin-top: 20px">
          <a-row class="mb-4">
            <a-col :span="6" style="padding-right: 20px">
              <a-checkbox v-model:checked="localTemplate.object.view.default.showAddNewButton"> showAddNewButton </a-checkbox>
              <div></div>
              addNewButtonLabel<br />
              <a-input v-model:value="localTemplate.object.view.default.addNewButtonLabel" placeholder="New button label" />
            </a-col>

            <a-col :span="6" style="padding-right: 20px">
              <a-checkbox v-model:checked="localTemplate.object.view.default.showEditButton"> showEditButton </a-checkbox>
              <div></div>
              editButtonLabel<br />
              <a-input v-model:value="localTemplate.object.view.default.editButtonLabel" placeholder="Edit button label" />
            </a-col>

            <a-col :span="6" style="padding-right: 20px">
              <a-checkbox v-model:checked="localTemplate.object.view.default.showDeleteButton"> showDeleteButton </a-checkbox>
              <div></div>
              deleteButtonLabel<br />
              <a-input v-model:value="localTemplate.object.view.default.deleteButtonLabel" placeholder="Delete button label" />
            </a-col>
          </a-row>

          <a-row class="mb-4">
            <a-col :span="6" style="padding-right: 20px">
              cardsTitleFiledId:<br />
              <a-select
                v-model:value="localTemplate.object.view.default.kanban.cardsTitleFiledId"
                :options="selectedBlueprintFields()"
                :filter-option="filterOption"
                showSearch
                style="width: 100%"
              ></a-select>
            </a-col>
            <a-col :span="6" style="padding-right: 20px">
              cardsOrderFiledId:<br />
              <a-select
                v-model:value="localTemplate.object.view.default.kanban.cardsOrderFiledId"
                :options="selectedBlueprintFields('number')"
                :filter-option="filterOption"
                showSearch
                style="width: 100%"
              ></a-select>
            </a-col>
          </a-row>

          <a-divider orientation="center"> Columns settings </a-divider>

          <a-row class="mb-4">
            <a-col :span="6" style="padding-right: 20px">
              columnTitleFieldId:<br />
              <a-select
                v-model:value="localTemplate.object.view.default.kanban.columnTitleFieldId"
                :options="referenceBlueprintFields()"
                :filter-option="filterOption"
                showSearch
                style="width: 100%"
                placeholder="Column title field"
              ></a-select>
            </a-col>
            <a-col :span="6" style="padding-right: 20px">
              columnOrderFieldId:<br />
              <a-select
                v-model:value="localTemplate.object.view.default.kanban.columnOrderFieldId"
                :options="referenceBlueprintFields('number')"
                :filter-option="filterOption"
                showSearch
                style="width: 100%"
                placeholder="Column number order field"
              ></a-select>
            </a-col>
          </a-row>
        </div>
        <div style="margin-top: 20px">
          <a-row>
            <a-col :span="24" class="mb-3">
              <a-button @click="tokenModalVisible = true" class="mb-2">Show tokens</a-button>
              <TokensModal
                :blueprint-id="selectedBlueprint._id"
                :visible="tokenModalVisible"
                :ownerDetailsExist="template.object.view.default.convertOwnerIdToOwnerDetails"
                @close="tokenModalVisible = false"
              ></TokensModal>
              <div class="mb-3">
                Html template before<br />
                <Codemirror
                  v-model="localTemplate.object.view.contentHtml"
                  :options="{
                    btabSize: 4,
                    mode: 'text/html',
                    theme: 'base16-dark',
                    lineNumbers: true,
                    line: true,
                  }"
                  :extensions="extensions"
                  :style="{ 'min-height': '200px', 'max-height': '500px' }"
                />
              </div>
              <div>
                Html template after<br />
                <Codemirror
                  v-model="localTemplate.object.view.contentHtmlAfter"
                  :options="{
                    btabSize: 4,
                    mode: 'text/html',
                    theme: 'base16-dark',
                    lineNumbers: true,
                    line: true,
                  }"
                  :extensions="extensions"
                  :style="{ 'min-height': '200px', 'max-height': '400px' }"
                />
              </div>
            </a-col>
          </a-row>
        </div>
      </a-tab-pane>

      <a-tab-pane key="2" tab="Content HTML settings">
        <a-row v-if="template.object.view?.contentHtmlSettings">
          <a-col :span="8">
            <a-checkbox v-model:checked="localTemplate.object.view.contentHtmlSettings.idToLabel">
              idToLabel {{ template.object.view.contentHtmlSettings.idToLabel }}
            </a-checkbox>
          </a-col>

          <a-col :span="8">
            <a-checkbox v-model:checked="localTemplate.object.view.contentHtmlSettings.fieldReferenceData"> fieldReferenceData </a-checkbox>
          </a-col>

          <a-col :span="8">
            <a-checkbox v-model:checked="localTemplate.object.view.contentHtmlSettings.excludeFieldMultipleReferenceData">
              excludeFieldMultipleReferenceData
            </a-checkbox>
          </a-col>
        </a-row>
      </a-tab-pane>
    </a-tabs>

    <template #footer>
      <a-button key="submit" type="primary" @click="close"> Ok </a-button>
    </template>
  </a-modal>
</template>

<script>
import { Codemirror } from 'vue-codemirror';
import { javascript } from '@codemirror/lang-javascript';
import { oneDark } from '@codemirror/theme-one-dark';

import TokensModal from '@/core/components/TokensModal.vue';
import { TemplatesActions } from '@/apps/templateManagement/shared/templateManagement.store';

export default {
  name: 'ElementSettings',
  props: ['selectedBlueprint', 'template', 'templates', 'blueprints', 'modalSetariElementVisible'],
  emits: ['update:modalSetariElementVisible'],
  components: {
    Codemirror,
    TokensModal,
  },
  data() {
    return {
      modalView: this.modalSetariElementVisible,
      tokenModalVisible: false,
      localTemplate: this.template ?? null,
      extensions: null,
    };
  },
  mounted() {
    this.localTemplate = this.template ?? null;
    const extensions = [javascript(), oneDark];
    this.extensions = extensions;
  },
  watch: {
    localTemplate: {
      // update stored template.
      deep: true,
      handler(value) {
        TemplatesActions.setTemplate(value);
      },
    },
  },
  methods: {
    close() {
      this.$emit('update:modalSetariElementVisible', false);
    },
    filterOption(input, option) {
      return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    },
    referenceBlueprintFields(type = null) {
      const referenceField = this.selectedBlueprint.fields.find(field => field._id === this.localTemplate.object.fieldId);
      const referenceBlueprint = this.blueprints.find(bp => bp._id === referenceField.structure.ruleset.blueprintId);
      return referenceBlueprint.fields.reduce((acc, field) => {
        if (type === field.structure.type || type === null) {
          acc.push({
            value: field.id,
            label: field.label,
          });
        }
        return acc;
      }, []);
    },
    selectedBlueprintFields(type = null) {
      return this.selectedBlueprint.fields.reduce((acc, field) => {
        if (type === field.structure.type || type === null) {
          acc.push({
            value: field.id,
            label: field.label,
          });
        }
        console.log('acc', acc);
        return acc;
      }, []);
    },
  },
};
</script>
